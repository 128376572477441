
backendHookRunBlock.$inject = ['Backend', 'securityRetryQueue', '$injector'];

export function backendHookRunBlock(Backend, queue, $injector) {
    Backend.addErrorInterceptor(function (response, deferred, responseHandler) {
        if (response.status === 401 || response.status === -1) {
            queue.pushRetryFn('unauthorized-server', function retryRequest() {
                // We must use $injector to get the $http service to prevent circular dependency
                $injector.get('$http')(response.config).then(responseHandler, deferred.reject);
            });
            return true;
        }
        return false; // error not handled
    });
}