import "./security"
import "./interceptor"
import "./login/login"
import "./authorization"
import angular from 'angular';

angular.module('security', [
    'security.service',
    'security.interceptor',
    'security.login',
    'security.authorization']);