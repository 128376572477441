import angular from 'angular';

let module = angular.module('locale.toggle', ['pascalprecht.translate'])

.controller('localeToggle', ['$translate', function($translate) {
    let LOCALES = $translate.getAvailableLanguageKeys();
    let service = {
        get options() {
            return LOCALES;
        },
        preference: $translate.use() | 'en',
        next: next,
        use: setLocale
    }

    function next() {
        setLocale(LOCALES[ ( LOCALES.indexOf(service.preference) + 1 ) % LOCALES.length ]);
    }

    function setLocale(value) {
        if (value) {
            service.preference = value;
            $translate.use(service.preference);
        }
    }

    return service;
}])

export { module };