
resourceFilter.$inject = ['Backend'];

export function resourceFilter(Backend) {
    var cached = {};
    function resourceFilter(input, property) {
      if (input) {
        if (input in cached) {
          // avoid returning a promise!
          if (typeof cached[input].then === 'function') return undefined;
          if (property !== undefined) return cached[input][property];
          return cached[input];
        } else {
          cached[input] = Backend.one(input).get();
          cached[input].then(resource => {
            cached[input] = resource;
          });
        }
      }
    }
    resourceFilter.$stateful = true;
    return resourceFilter;
}