import "./retryQueue"
import "restangular"
import angular from 'angular';

angular.module('security.interceptor', ['security.retryQueue'])

// This http interceptor listens for authentication failures
.factory('securityInterceptor', ['$injector', 'securityRetryQueue', function($injector, queue) {
  
  return {
    'requestError' : function(rejection) {
      // Intercept failed requests
      if(rejection.status === 401) {
        // The request bounced because it was not authorized - add a new request to the retry queue
        rejection = queue.pushRetryFn('unauthorized-server', function retryRequest() {
          // We must use $injector to get the $http service to prevent circular dependency
          return $injector.get('$http')(rejection.config);
        });
      }
      return rejection;
    }
  };
}])

// We have to add the interceptor to the queue as a string because the interceptor depends upon service instances that are not available in the config block.
.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push('securityInterceptor');
}])


;