import angular from 'angular';
import './resourceAutocomplete.style.scss';

class ResourceAutocompleteController {
    constructor(Backend, PaginatorFactory, $q) {
        this.Backend = Backend;
        this.PaginatorFactory = PaginatorFactory;
        this.$q = $q;
        this.isDisabled = false;
        this.noCache = false;
        this.searchText = "";
        this.listParams = {};
        this.selectedItem;
    }

    $onInit() {
        this.repository = this.Backend.all(this.collection);
        this.property = this.property || 'name';

        this.ngModelCtrl.$render = () => {
            let resourcePath = this.ngModelCtrl.$modelValue;
            if (resourcePath) {
                let resourceId = resourcePath.substring(resourcePath.lastIndexOf('/') + 1)
                this.searchText = resourceId;
            } else {
                this.searchText = "";
            }
        };
    }

    searchTextChange(/*query*/) {
        
    }

    selectedItemChange(item) {
        if (item) {
            this.ngModelCtrl.$setViewValue(item['@id']);
        } else {
            this.ngModelCtrl.$setViewValue(null);
        }
    }

    querySearch(query) {
        let deferred = this.$q.defer();
        this.listParams[this.property] = query;
        this.paginator = this.PaginatorFactory(this.repository.withHttpConfig({ cache: true }), this.listParams);
        let page = this.paginator.getPage(0);
        if (angular.isArray(page)) {
            deferred.resolve(page);
        } else {
            page.then(result => {
                if (result) {
                    deferred.resolve(result);
                } else {
                    deferred.reject();
                }
            }).catch(() => {
                deferred.reject();
            });
        }
        return deferred.promise;
    }

    newResource(name) {
        console.log('newResource', name);
    }
}
ResourceAutocompleteController.$inject = ['Backend', 'PaginatorFactory', '$q'];

export const resourceAutocompleteComponent = {
    controller: ResourceAutocompleteController,
    bindings: {
        collection: '@',
        property: '@?',
        placeholder: '@?',
        ngModel: '<'
    },
    require: {
        ngModelCtrl: "ngModel"
    },
    template: `
        <md-autocomplete
            class="resource-autocomplete-field"
            ng-disabled="$ctrl.isDisabled"
            md-no-cache="$ctrl.noCache"
            md-selected-item="$ctrl.selectedItem"
            md-search-text-change="$ctrl.searchTextChange($ctrl.searchText)"
            md-search-text="$ctrl.searchText"
            md-selected-item-change="$ctrl.selectedItemChange(item)"
            md-items="item in $ctrl.querySearch($ctrl.searchText)"
            md-item-text="item[$ctrl.property]"
            md-min-length="2"
            placeholder="{{ ::$ctrl.placeholder }}"
            input-aria-labelledby="resource"
            md-clear-button=false>
            <md-item-template>
                <span md-highlight-text="$ctrl.searchText" md-highlight-flags="^i">{{item[$ctrl.property]}}</span>
            </md-item-template>
            <md-not-found>
                No states matching "{{$ctrl.searchText}}" were found.
                <a ng-click="$ctrl.newResource($ctrl.searchText)">Create a new one!</a>
            </md-not-found>
        </md-autocomplete>
    `
}