
import angular from "angular";
import 'restangular';

import { Backend } from "./backend.module";
import { backendHookRunBlock } from "./backend.hook";
import { backendAuthorizationRunBlock } from "./backendAuthorization.module";
import { authHookRunBlock } from "./requiresAuth.hook";
import { PaginatorComponent } from "./paginator.component";
import { paginatorFactory } from "./paginator.factory";
import { lastOfSegments } from "./lastOfSegments.filter";
import { resourceFilter } from "./resource.filter";
import { twoColumnLayoutComponent } from "./twoColumnLayout.component";
import { resourceAutocompleteComponent } from './resourceAutocomplete.component.js';

export const COMMON_MODULE = angular.module('common', [
    'restangular'
]);

COMMON_MODULE.factory('PaginatorFactory', paginatorFactory);
COMMON_MODULE.directive('paginator', PaginatorComponent);
COMMON_MODULE.component('twoColumnLayout', twoColumnLayoutComponent);
COMMON_MODULE.component('resourceAutocomplete', resourceAutocompleteComponent);
COMMON_MODULE.filter('lastOfSegments', lastOfSegments);
COMMON_MODULE.filter('resource', resourceFilter);
COMMON_MODULE.service('Backend', Backend);
COMMON_MODULE.run(backendHookRunBlock);
COMMON_MODULE.run(backendAuthorizationRunBlock);
COMMON_MODULE.run(authHookRunBlock);