
import "./twoColumnLayout.style.scss";

class TwoColumnLayoutController {
    constructor($mdSidenav, $scope, $mdUtil) {
        this.$mdSidenav = $mdSidenav;
        this.$scope = $scope;
        this.$mdUtil = $mdUtil;
        this.navId = $mdUtil.nextUid();
    }

    $onInit() {
        this.destroyFocusListener = this.$scope.$watch(() => this.focus, (column) => {
            if (column === 'left') {
                this.$mdUtil.nextTick(() => this.blurRightColumn());
            } else if (column === 'right') {
                this.$mdUtil.nextTick(() => this.focusRightColumn());
            }
        });
    }

    $onDestroy() {
        this.destroyFocusListener();
    }

    focusRightColumn() {
        let sidenav = this.$mdSidenav(this.navId);
        if (sidenav.isOpen()) {
            return null;
        }
        return sidenav.open().then(() => {
            sidenav.onClose(() => {
                this.focus = 'left';
            });
        });
    }

    blurRightColumn() {
        let sidenav = this.$mdSidenav(this.navId);
        if (!sidenav.isOpen()) {
            return null;
        }
        return sidenav.close();
    }
}
TwoColumnLayoutController.$inject = ['$mdSidenav', '$scope', '$mdUtil'];

export const twoColumnLayoutComponent = {
    controller: TwoColumnLayoutController,
    transclude: {
        'left': 'columnLeft',
        'right': 'columnRight',
    },
    bindings: {
        focus: '=?'
    },
    template: `
        <div 
            class="tcl-column tcl-column-left" 
            ng-transclude='left'
            layout="column">
            left
        </div>
        <md-sidenav 
            class="tcl-column tcl-column-right md-sidenav-right" 
            ng-transclude='right'
            md-component-id="{{::$ctrl.navId}}"
            md-is-locked-open="$mdMedia('gt-xs')"
            md-colors="{ background: 'background', 'border-color': 'primary-hue-1' }">
            right
        </md-sidenav>
        <div class="controller" md-colors="{ background: 'background-hue-2-0.8' }">
            <md-switch ng-model="$ctrl.lockedOpen" aria-label="Switch 1" md-invert>
                Locked open
            </md-switch>
        </div>
    `
}