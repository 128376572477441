import 'restangular';
import angular from 'angular';

angular.module('resources.backend', ['restangular'])

.factory('OmniyouRestangular', ['Restangular', '$log', function(Restangular, $log) {
    return Restangular.withConfig(function(RestangularConfigurer) {
        // The URL of the API endpoint
        RestangularConfigurer.setBaseUrl(process.env.OMNIYOU_API);

        // JSON-LD @id support
        RestangularConfigurer.setRestangularFields({
            id: '@id',
            selfLink: '@id'
        });
        RestangularConfigurer.setSelfLinkAbsoluteUrl(false);

        // Hydra collections support
        RestangularConfigurer.addResponseInterceptor(function(data, operation) {
            // Remove trailing slash to make Restangular working
            function populateHref(data) {
                if (data['@id']) {
                    data.href = data['@id'].substring(1);
                }
            }

            // Populate href property for the collection
            populateHref(data);

            $log.debug('responseInterceptor', {data, operation});

            if ('getList' === operation) {
                var collectionResponse = data['hydra:member'];
                collectionResponse.metadata = {};

                // Put metadata in a property of the collection
                angular.forEach(data, function(value, key) {
                    if ('hydra:member' !== key) {
                        collectionResponse.metadata[key] = value;
                    }
                });

                // Populate href property for all elements of the collection
                angular.forEach(collectionResponse, function(value) {
                    populateHref(value);
                });

                return collectionResponse;
            }

            return data;
        });
    });
}]);