
paginatorFactory.$inject = [];

export function paginatorFactory() {
    return function(resource, query, pageSize = 20) {
        const loadedPages = {};
        var numItems = null;
        
        Object.keys(query).forEach(key => {
            if (!query[key]) {
                delete query[key];
            }
        });
        
        return {
            getItemAtIndex,
            getLength,
            getPage,
            get $pageSize() {
                return pageSize;
            },
            get $numberOfPages() {
                return Math.ceil(numItems / pageSize);
            }
        }
        
        function getPage(pageNumber) {
            var page = loadedPages[pageNumber];
            
            if (page) {
                return page;
            } else if (page !== null) {
                return fetchPage_(pageNumber);
            }
        }
        
        function getItemAtIndex(index) {
            var pageNumber = Math.floor(index / pageSize);
            var page = loadedPages[pageNumber];
            
            if (page) {
                return page[index % pageSize];
            } else if (page !== null) {
                fetchPage_(pageNumber);
            }
        }
        
        function getLength() {
            if (null === numItems) {
                getItemAtIndex(0);
                return undefined;
            }
            return numItems;
        }
        
        function fetchPage_(pageNumber) {
            loadedPages[pageNumber] = null;
            const pageQuery = query || {};
            pageQuery.itemsPerPage = pageSize;
            pageQuery.page = pageNumber + 1;
            return resource.getList(pageQuery).then((response) => {
                numItems = response.metadata['hydra:totalItems'];
                loadedPages[pageNumber] = response;
                return response;
            })
        }
    }
}
