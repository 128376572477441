import angular from 'angular';

import "/src/common/locale/toggle";


angular.module('security.login.toolbar', ['locale.toggle'])

.directive('loginToolbar', ['security', '$mdPanel', function (security, $mdPanel) {
    return {
        template: require('./toolbar.tpl.html').default,
        restrict: 'E',
        replace: true,
        scope: true,
        link: function ($scope, $element) {
            $scope.isAuthenticated = security.isAuthenticated;
            $scope.login = security.showLogin;
            $scope.logout = logout;
            $scope.showMenu = openPanel;

            $scope.$watch(function () {
                return security.currentUser;
            }, function (currentUser) {
                $scope.currentUser = currentUser;
            });

            let menuPanelPromise = null;
            let menuPanelRef = null;

            function openPanel() {
                if (!menuPanelPromise) {
                    menuPanelPromise = $mdPanel.open(getPanelConfig()).then((ref) => {
                        menuPanelRef = ref;
                    });
                }
            }

            function closePanel() {
                if (menuPanelPromise && menuPanelRef) {
                    menuPanelRef.close();
                    menuPanelRef = null;
                }
            }

            function logout() {
                closePanel();
                security.logout();
            }

            function onRemoving() {
                menuPanelPromise = null;
            }

            function getPanelConfig() {
                return {
                    scope: $scope,
                    id: 'account-menu',
                    attachTo: angular.element(document.body),
                    template: require('./menu.tpl.html').default,
                    position: $mdPanel.newPanelPosition()
                        .relativeTo($element)
                        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.ALIGN_TOPS),
                    animation: $mdPanel.newPanelAnimation()
                        .openFrom($element)
                        .duration(150)
                        .closeTo($element)
                        .withAnimation({
                            open: "account-menu-animation-open",
                            close: "account-menu-animation-close"
                        }),
                    panelClass: 'account-menu',
                    hasBackdrop: true,
                    trapFocus: true,
                    disableParentScroll: false, // TODO: debug and enable this
                    zIndex: 60,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: true,
                    controllerAs: 'ctrl',
                    controller: [function () {
                        this.getSection = () => {
                            return $scope.section;
                        }
                        this.close = () => {
                            $scope.toggle();
                        }
                        // this.isSelected = (e) => {
                        //   return menu.isPageSelected(e.state, e.stateParams);
                        // }
                    }],
                    origin: $element,
                    groupName: 'body',
                    onRemoving: onRemoving
                };
            }
        }
    };
}]);