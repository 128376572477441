// bootstrap.js

"use strict";

import * as angular from "angular";

import uiRouter from "@uirouter/angularjs";
import '@uirouter/angularjs/release/stateEvents';
import 'angular-translate';
import 'angular-animate';
import 'angular-aria';
import 'angular-material';
import 'angular-messages';
import 'angular-sanitize';
// import 'angular-ui-carousel';
import 'restangular';
import "@mdi/font/scss/materialdesignicons.scss";
import "angular-material/angular-material.scss";

import ocLazyLoad from "oclazyload";

import "./firebase";
import { MAIN_MODULE } from '../main/main.module';
import { COMMON_MODULE } from '../common/common.module';

//
// Since it is exported, other parts of the application (in other files) can then import it and register things.
// In bootstrap.js, the module is imported, and the components, services, and states are registered.
export const ngmodule = angular.module("core", [
  uiRouter,
  ocLazyLoad,
  MAIN_MODULE.name,
  COMMON_MODULE.name
]);

angular.bootstrap(document, [ngmodule.name], {
  strictDi: true
});