/**
 * The controller for the `app` component.
 */
class AuthedController {
    constructor(security, $state) {
        this.security = security;
        this.$state = $state;

        this.isAuthenticated = security.isAuthenticated;

    }

    logout() {
        let { security, $state } = this;
        security.logout();
        // Reload states after authentication change
        return $state.go('welcome', {}, { reload: true });
    }

    isActive(glob) {
        return this.$state.includes(glob);
    }
}
AuthedController.$inject = ['security', '$state'];


export const app = {
    controller: AuthedController,
    template: `
    <md-toolbar class="toolbar">
        <div class="md-toolbar-tools">
            <md-button aria-label="Products" ui-sref="products">
                Products
            </md-button>
            <md-button aria-label="Reports" ui-sref="blueprints">
                Reports
            </md-button>
            <h2 flex md-truncate></h2>
            <md-menu ng-if="$ctrl.isAuthenticated()" md-position-mode="target-right target">
                <md-button class="md-fab md-mini" aria-label="Account" ng-click="$mdMenu.open($event)">
                    <img ng-src="{{$ctrl.security.currentUser.photoURL}}" class="md-avatar" alt="{{$ctrl.security.currentUser.displayName[0]}}" />
                    <md-tooltip>{{$ctrl.security.currentUser.email}}</md-tooltip>
                </md-button>
                <md-menu-content width="4">

                    <md-list-item>
                        <md-switch class="" ng-model="darkCtrl.$darkmode" md-invert flex>Darkmode</md-switch>
                    </md-list-item>

                    <md-divider></md-divider>

                    <md-list-item>
                        <md-button class="md-icon-button" ng-click="">
                            <i class="mdi mdi-brightness-auto md-icon-deco"></i>
                        </md-button>
                        <md-switch class="" ng-model="darkCtrl.$darkmode" md-invert flex>Darkmode</md-switch>
                    </md-list-item>

                    <md-list-item ng-click="localeCtrl.next()">
                        <i class="mdi mdi-earth"></i>
                        <p>Taal</p>
                        <div class="dense-radio-group">
                            <input ng-repeat-start="option in ['nl', 'en']" id="local-radio-{{option}}" type="radio" ng-model="preference" ng-value="option">
                            <label ng-repeat-end for=local-radio-{{option}} md-ink-ripple title="nederlands">{{ option | uppercase }}</label>
                        </div>
                    </md-list-item>

                    <md-divider></md-divider>

                    <md-menu-item>
                        <md-button ng-click="$ctrl.logout()"> 
                            <div layout="row" flex>
                                <p flex>Log Out</p>
                                <i class="mdi mdi-exit"></i>
                            </div>
                        </md-button>
                    </md-menu-item>

                </md-menu-content>
            </md-menu>
        </div>
    </md-toolbar>
    <md-content ui-view class="content" layout="column"></md-content>
    <md-content ui-view="products" class="content" ng-show="$ctrl.isActive('products.**')" flex layout="column"></md-content>
    <md-content ui-view="blueprints" class="content" ng-show="$ctrl.isActive('blueprints.**')" flex layout="column"></md-content>
    `
}