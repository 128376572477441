
/**
 * The controller for the `login` component
 *
 * The `login` method validates the credentials.
 * Then it sends the user back to the `returnTo` state, which is provided as a resolve data.
 */
class RegisterController {
    constructor(security, $state) {
        this.credentials = {
            username: null,
            password: null
        };

        this.register = (credentials) => {
            console.log({credentials});
            this.authenticating = true;

            const returnToOriginalState = () => {
                let state = this.returnTo.state();
                let params = this.returnTo.params();
                let options = Object.assign({}, this.returnTo.options(), { reload: true });
                $state.go(state, params, options);
            };

            const showError = (errorMessage) =>
                this.errorMessage = errorMessage;

            security.login(credentials.username, credentials.password)
                .then(returnToOriginalState)
                .catch(showError)
                .finally(() => this.authenticating = false);
        }
    }
}
RegisterController.$inject = ['security', '$state'];

/**
 * This component renders a faux authentication UI
 *
 * It prompts for the username/password (and gives hints with bouncy arrows)
 * It shows errors if the authentication failed for any reason.
 */
export const register = {
    bindings: { returnTo: '<' },

    controller: RegisterController,

    template: `
        <md-card>
            <md-card-title>
                <md-card-title-text>
                    <span class="md-headline">Register</span>
                </md-card-title-text>
            </md-card-title>
            <form name="form" ng-submit="$ctrl.login($ctrl.credentials)">
                <md-card-content>
                    <md-input-container class="md-block">
                        <label>Name</label>
                        <input name="name" type="text" ng-model="$ctrl.credentials.name" autofocus>
                    </md-input-container>
                    <md-input-container class="md-block">
                        <label>Email</label>
                        <input name="login" type="email" ng-model="$ctrl.credentials.username" required>
                    </md-input-container>
                    <md-input-container class="md-block">
                        <label>Password</label>
                        <input name="pass" auto-complete="new-password" type="password" ng-model="$ctrl.credentials.password" required>
                    </md-input-container>
                    <md-input-container class="md-block">
                        <label>Conform password</label>
                        <input name="pass2" auto-complete="confirm-password" type="password" ng-model="$ctrl.credentials.password2" required>
                    </md-input-container>
                    <md-checkbox aria-label="TOS" ng-model="data.cb3" required>
                        Terms and conditions
                    </md-checkbox>
                </md-card-content>
                <md-card-actions layout="row" layout-align="end center">
                    <md-button ui-sref="^">Cancel</md-button>
                    <md-button type="submit" ng-disabled='form.$invalid || $ctrl.authenticating'>Register</md-button>
                </md-card-actions>
            </form>
        </md-card>
    `
};