import { 
    getAuth, 
    onAuthStateChanged, 
    createUserWithEmailAndPassword, 
    updateProfile, 
    signInWithEmailAndPassword, 
    signOut, 
    GoogleAuthProvider, 
    signInWithRedirect, 
    sendPasswordResetEmail, 
    sendSignInLinkToEmail, 
    sendEmailVerification,
    getRedirectResult
} from "firebase/auth";
import angular from 'angular';

export const SECURITY_MODULE = angular.module('security.service', [
    'security.retryQueue',    // Keeps track of failed requests that need to be retried once the user logs in
    'security.login',         // Contains the login form template and controller
    'ngMaterial'              // Used to display the login form as a modal dialog.
]);
  
SECURITY_MODULE.factory('security', ['$http', '$q', 'securityRetryQueue', '$rootScope', '$log', function($http, $q, queue, $rootScope, $log) {
  
    // function refresh(url) {
      // $state.go($state.current.name, $state.params, { reload: true });
    // }

    /**
     * Helper that returns a promise which resolves when the initial auth state has been
     * fetched from the Firebase server. This never rejects and resolves to undefined.
     *
     * @return {Promise<Object>} A promise fulfilled when the server returns initial auth state.
     */
    function _initialAuthResolver() {
      return $q(function(resolve) {
        var off;
        function callback() {
          // Turn off this onAuthStateChanged() callback since we just needed to get the authentication data once.
          off();
          resolve();
        }
        off = onAuthStateChanged(auth, callback);
      });
    }

    function _routerMethodOnAuthPromise(rejectIfAuthDataIsNull, rejectIfEmailNotVerified) {
      // wait for the initial auth state to resolve; on page load we have to request auth state
      // asynchronously so we don't want to resolve router methods or flash the wrong state
      return _initialAuthResolver().then(function() {
        // auth state may change in the future so rather than depend on the initially resolved state
        // we also check the auth data (synchronously) if a new promise is requested, ensuring we resolve
        // to the current auth state and not a stale/initial state
        var res = null;
        if (rejectIfAuthDataIsNull && service.currentUser === null) {
          res = $q.reject("AUTH_REQUIRED");
        } else if (rejectIfEmailNotVerified && !auth.emailVerified) {
          res = $q.reject("EMAIL_VERIFICATION_REQUIRED");
        } else {
          res = $q.when(auth);
        }
        return res;
      });
    }

    // function requireSignIn(requireEmailVerification) {
    //   return _routerMethodOnAuthPromise(true, requireEmailVerification);
    // }

    function waitForSignIn() {
      return _routerMethodOnAuthPromise(false, false);
    }
  
    // Register a handler for when an item is added to the retry queue
    queue.onItemAddedCallbacks.push(function() {
      if ( queue.hasMore() ) {
        // $rootScope.$emit('$onSecurityEvent', {
        //   detail: "AUTH_REQUIRED"
        // });
      }
    });

    const auth = getAuth();
  
    // The public API of the service
    var service = {
  
        // Get the first reason for needing a login
        getLoginReason: function() {
            return queue.retryReason();
        },

        createUser: function(email, password) {
            return createUserWithEmailAndPassword(auth, email, password).then(function(user) {
                $rootScope.$emit('$onSecurityEvent', {
                    detail: "USER_CREATED"
                });
                return user;
            })
        },

        updateProfile: function(properties) {
            return updateProfile(service.currentUser, properties);
        },
    
        // Attempt to authenticate a user by the given email and password
        login: function(email, password) {
            return signInWithEmailAndPassword(auth, email, password).then(function(user) {
                $rootScope.$emit('$onSecurityEvent', {
                    detail: "USER_LOGGED_IN"
                });
                return user;
            });
        },

        loginWith: function(provider) {
            if (provider === 'google') {
                provider = new GoogleAuthProvider();
                // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
                // provider.setCustomParameters({
                //   'login_hint': 'user@example.com'
                // });
            } else {
                throw new Error('Unsupported login provider!');
            }

            return signInWithRedirect(auth, provider);
        },

        sendSignInLinkToEmail: function(email, redirect) {
            return sendSignInLinkToEmail(auth, email, {
                handleCodeInApp: false,
                url: redirect
            });
        },

        sendEmailVerification: function(redirect) {
            return sendEmailVerification(service.currentUser, {
                handleCodeInApp: false,
                url: redirect
            });
        },

        recoverPassword: function(email, redirect) {
            return sendPasswordResetEmail(auth, email, {
                handleCodeInApp: false,
                url: redirect
            });
        },
    
        // Give up trying to login and clear the retry queue
        cancelLogin: function() {
            // closeLoginDialog(false);
            // redirect();
        },
    
        // Logout the current user and redirect
        logout: function() {
            signOut(auth).then(() => {
            $rootScope.$emit('$onSecurityEvent', {
                detail: "SIGNED_OUT"
            });
            });
        },
    
        // Ask the backend to see if a user is already authenticated - this may be from a previous session.
        requestCurrentUser: function() {
            let user = service.currentUser;
            if ( service.isAuthenticated() ) {
                return $q.when(user);
            } else {
                return waitForSignIn().then(auth => auth.currentUser);
            }
        },

        routerMethodOnAuthPromise: function(rejectIfAuthDataIsNull, rejectIfEmailNotVerified) {
            return _routerMethodOnAuthPromise(rejectIfAuthDataIsNull, rejectIfEmailNotVerified);
        },
    
        // Information about the current user
        currentUser: auth.currentUser,
    
        // Is the current user authenticated?
        isAuthenticated: function(){
            return !!service.currentUser;
        },
        
        // Is the current user an adminstrator?
        isAdmin: function() {
            return !!(service.currentUser && service.currentUser.admin);
        },

        getRedirectResult: function() {
            return getRedirectResult(auth);
        }
    };

    onAuthStateChanged(auth, function(user) {
        $log.debug('onAuthStateChanged', {user});
        service.currentUser = user;
    });
  
    return service;
}]);