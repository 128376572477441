
import "./paginator.style.scss";

PaginatorController['$inject'] = ['$log', '$scope', '$mdUtil'];

export function PaginatorController($log, $scope) {

    var itemParts,
        itemExpr,
        noBlur,
        pageIndex;

    return {
        $onInit,
        focusInput,
        loadingIsVisible,
        notFoundVisible,
        prevPage,
        nextPage,
        select,
        listLeave: onListLeave,
        listEnter: onListEnter,
        get items() {
            return getItems();
        },
        get noBlur() {
            return noBlur;
        },
        get pageIndex() {
            return pageIndex;
        },
        hidden: false
    }

    function $onInit() {
        $log.log("PaginatorController", this, $scope)

        itemParts = $scope.itemsExpr.split(/ in /i),
        itemExpr = itemParts[ 1 ];
        pageIndex = 0;
    }

    function getItems() {
        const items = $scope.$parent.$eval(itemExpr);
        if (items) {
            items.getItemAtIndex(0)
            return items
        } else {
            return [];
        }
    }

    function focusInput() {

    }

    function loadingIsVisible() {
        return false;
    }

    function onListEnter () {
        noBlur = true;
    }

    function onListLeave () {
        // if (!hasFocus && !ctrl.hidden) elements.input.focus();
        noBlur = false;
    }

    function notFoundVisible() {
        return false;
    }

    function nextPage() {
        ++pageIndex;
    }

    function prevPage() {
        --pageIndex;
    }

    function select(item) {
        $log.log('select', {item});
        // force form to update state for validation
        $scope.selectedItem = item;
    }
    
}

PaginatorComponent.$inject = [];

export function PaginatorComponent() {
    var REPEAT_STANDARD = 'paged';
    var REPEAT_VIRTUAL = 'virtual';
    var REPEAT_MODES = [REPEAT_STANDARD, REPEAT_VIRTUAL];

    function getRepeatMode(modeStr) {
        if (!modeStr) { return REPEAT_VIRTUAL; }
        modeStr = modeStr.toLowerCase();
        return  REPEAT_MODES.indexOf(modeStr) > -1 ? modeStr : REPEAT_VIRTUAL;
    }

    return {
        controller: PaginatorController,
        controllerAs: '$appPaginatorCtrl',
        scope: {
            mdMode: '=?mdMode',
            menuClass: '@?mdMenuClass',
            itemsExpr: '@mdItems',
            selectedItem: '=?appSelectedItem',
        },
        template: function (element, attr) {
            var noItemsTemplate = getNoItemsTemplate(),
            itemTemplate    = getItemTemplate();
            // leftover        = element.html(),
            // tabindex        = attr.tabindex;
            // Set our attribute for the link function above which runs later.
            // We will set an attribute, because otherwise the stored variables will be trashed when
            // removing the element is hidden while retrieving the template. For example when using ngIf.
            if (noItemsTemplate) element.attr('md-has-not-found', true);
            // Always set our tabindex of the autocomplete directive to -1, because our input
            // will hold the actual tabindex.
            element.attr('tabindex', '-1');
            return '\
                <app-paginator-wrap\
                    ng-class="{ \'md-menu-showing\': !$appPaginatorCtrl.hidden, \
                                \'md-show-clear-button\': !!clearButton }">\
                    <md-progress-linear\
                        class="' + (attr.mdFloatingLabel ? 'md-inline' : '') + '"\
                        ng-if="$appPaginatorCtrl.loadingIsVisible()"\
                        md-mode="indeterminate"></md-progress-linear>\
                    ' + getContainer(attr.mdMenuContainerClass, attr.mdMode) + '\
                    <ul class="app-paginator-item-container"\
                        ng-class="::menuClass"\
                        id="ul-{{$appPaginatorCtrl.id}}"\
                        ng-mouseup="$appPaginatorCtrl.focusInput()"\
                        role="listbox">\
                        <li class="app-paginator-item" ' + getRepeatType(attr.mdMode) + ' ="item in $appPaginatorCtrl.items' + getRepeatTypeCallback(attr.mdMode) + '">' 
                            + itemTemplate
                        + '</li>'
                        + noItemsTemplate 
                    + '</ul>' + getContainerClosingTags(attr.mdMode)
                + '</app-paginator-wrap>';

            function getItemTemplate() {
                var templateTag = element.find('md-item-template').detach(),
                html = templateTag.length ? templateTag.html() : element.html();
                if (!templateTag.length) element.empty();
                return html;
            }

            function getNoItemsTemplate() {
                var templateTag = element.find('md-not-found').detach(),
                template = templateTag.length ? templateTag.html() : '';
                return template
                    ? '<li ng-if="$appPaginatorCtrl.notFoundVisible()" class="md-autocomplete-suggestion"\
                                md-autocomplete-parent-scope>' + template + '</li>'
                    : '';
            }

            function getContainer(menuContainerClass, repeatMode) {
                // prepend a space if needed
                menuContainerClass = menuContainerClass ? ' ' + menuContainerClass : '';

                if (isVirtualRepeatDisabled(repeatMode)) {
                    return '\
                        <div \
                            ng-hide="$appPaginatorCtrl.hidden"\
                            class="md-standard-list-container' + menuContainerClass + '"\
                            ng-class="{ \'md-not-found\': $appPaginatorCtrl.notFoundVisible() }"\
                            ng-mouseenter="$appPaginatorCtrl.listEnter()"\
                            ng-mouseleave="$appPaginatorCtrl.listLeave()"\
                            role="presentation">\
                        <div class="md-standard-list-scroller" role="presentation">';
                }

                return '\
                    <md-virtual-repeat-container\
                        ng-hide="$appPaginatorCtrl.hidden"\
                        class="md-virtual-repeat-container app-virtual-repeat-container' + menuContainerClass + '"\
                        ng-class="{ \'md-not-found\': $appPaginatorCtrl.notFoundVisible() }"\
                        ng-mouseenter="$appPaginatorCtrl.listEnter()"\
                        ng-mouseleave="$appPaginatorCtrl.listLeave()"\
                        role="presentation">';

                // return '\
                //     <md-virtual-repeat-container\
                //         md-auto-shrink\
                //         md-auto-shrink-min="1"\
                //         ng-hide="$appPaginatorCtrl.hidden"\
                //         class="md-virtual-repeat-container md-whiteframe-z1' + menuContainerClass + '"\
                //         ng-class="{ \'md-not-found\': $appPaginatorCtrl.notFoundVisible() }"\
                //         ng-mouseenter="$appPaginatorCtrl.listEnter()"\
                //         ng-mouseleave="$appPaginatorCtrl.listLeave()"\
                //         role="presentation">';
            }

            function getContainerClosingTags(repeatMode) {
                return isVirtualRepeatDisabled(repeatMode) ?
                '</div></div>\
                <div class="app-card-toolbar app-card-toolbar-secondary" layout="row" ng-class="{ \'hide-contents\': $appPaginatorCtrl.items.$numberOfPages < 2 }"> \
                    <span flex></span> \
                    <md-button  aria-label="add node" class="app-icon-btn" ng-click="$appPaginatorCtrl.prevPage($event)" ng-disabled="$appPaginatorCtrl.pageIndex < 1"  title="Previous page"> \
                        <i class="mdi mdi-chevron-left"></i> \
                    </md-button> \
                    <span class="app-icon">{{$appPaginatorCtrl.pageIndex + 1}}</span> \
                    <md-button aria-label="add node" class="app-icon-btn" ng-click="$appPaginatorCtrl.nextPage($event)" ng-disabled="$appPaginatorCtrl.pageIndex > $appPaginatorCtrl.items.$numberOfPages - 2" title="Next page"> \
                        <i class="mdi mdi-chevron-right"></i> \
                    </md-button> \
                </div>' : 
                '</md-virtual-repeat-container>';
            }

            function getRepeatType(repeatMode) {
                return isVirtualRepeatDisabled(repeatMode)  ?
                  'ng-repeat' : 'md-on-demand md-virtual-repeat';
            }

            function getRepeatTypeCallback(repeatMode) {
                return isVirtualRepeatDisabled(repeatMode)  ?
                  '.getPage($appPaginatorCtrl.pageIndex)' : '';
            }

            function isVirtualRepeatDisabled(repeatMode) {
                // ensure we have a valid repeat mode
                var correctedRepeatMode = getRepeatMode(repeatMode);
                return correctedRepeatMode !== REPEAT_VIRTUAL;
            }
        }
    }
}
