// app.states.js

"use strict";

function requireAuth($log, securityAuthorizationProvider) {
    return securityAuthorizationProvider.requireAuthenticatedUser
}

requireAuth.$inject = ['$log', 'securityAuthorizationProvider'];


export const appState = {
    name: 'app',
    redirectTo: 'welcome',
    component: 'app'
};

export const welcomeState = {
    parent: 'app',
    name: 'welcome',
    url: '/welcome',
    component: 'welcome'
};

export const homeState = {
    parent: 'app',
    name: 'home',
    url: '/home',
    component: 'home'
};

export const loginState = {
    name: 'login',
    url: '/login',
    component: 'login',
    resolve: { returnTo: returnTo }
};

export const registerState = {
    parent: 'login',
    name: 'register',
    url: '/register',
    component: 'register'
};

/**
 * A resolve function for 'login' state which figures out what state to return to, after a successful login.
 *
 * If the user was initially redirected to login state (due to the requiresAuth redirect), then return the toState/params
 * they were redirected from.  Otherwise, if they transitioned directly, return the fromState/params.  Otherwise
 * return the main "home" state.
 */
returnTo.$inject = ['$transition$'];
function returnTo($transition$) {
    if ($transition$.redirectedFrom() != null) {
        // The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate contacts)
        // Return to the original attempted target state (e.g., contacts)
        return $transition$.redirectedFrom().targetState();
    }
    
    let $state = $transition$.router.stateService;
    
    // The user was not redirected to the login state; they directly activated the login state somehow.
    // Return them to the state they came from.
    if ($transition$.from().name !== '') {
        return $state.target($transition$.from(), $transition$.params("from"));
    }
    
    // If the fromState's name is empty, then this was the initial transition. Just return them to the home state
    return $state.target('home');
}

export const productsFutureState = {
    parent: 'app',
    name: "products.**",
    url: "/products",
    component: "productCollectionComponent",
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../products/products.module').then(mod => $ocLazyLoad.load(mod.APP_PRODUCTS_MODULE))
    }
}

export const blueprintsFutureState = {
    parent: 'app',
    name: "blueprints.**",
    url: "/blueprints",
    component: "blueprintCollectionComponent",
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../paperBlueprints/blueprints.module').then(mod => $ocLazyLoad.load(mod.APP_BLUEPRINT_MODULE))
    }
}