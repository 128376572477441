/**
 * This run block registers a Transition Hook which protects
 * routes that requires authentication.
 *
 * This hook redirects to /login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
authHookRunBlock.$inject = ['$transitions'];
export function authHookRunBlock($transitions) {
    // Matches if the destination state's data property has a truthy 'requiresAuth' property
    let requiresAuthCriteria = {
        to: (state) => state.data && state.data.requiresAuth
    };

    // Function that returns a redirect for the current transition to the login state
    // if the user is not currently authenticated (according to the AuthService)

    let redirectToLogin = (transition) => {
        let security = transition.injector().get('security');
        let $state = transition.router.stateService;
        return security.requestCurrentUser().then((user) => {
            if (!user) {
                return $state.target('login', undefined, { location: false });
            }
        });
    };

    // Register the "requires auth" hook with the TransitionsService
    $transitions.onBefore(requiresAuthCriteria, redirectToLogin, { priority: 10 });
}