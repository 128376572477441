import { getAuth, onIdTokenChanged } from "firebase/auth";

const auth = getAuth();

backendAuthorizationRunBlock.$inject = ['$log', 'Backend'];

export function backendAuthorizationRunBlock($log, Backend) {
    onIdTokenChanged(auth, function(user) {
        $log.debug('backend onIdTokenChanged', {user});
        if (user && user.accessToken) {
            Backend.setDefaultHeaders({ 'Authorization': `Bearer ${user.accessToken}` });
        } else {
            Backend.setDefaultHeaders({ 'Authorization': undefined });
        }
    });
}