import angular from 'angular';

lastOfSegments.$inject = [];

export function lastOfSegments() {
    return function(input, seperator = '/') {
      if (angular.isString(input)) {
        return input.substring(input.lastIndexOf(seperator) + 1)
      } else {
        return input;
      }
    }
}