
import "./login.style.scss";

/**
 * The controller for the `login` component
 *
 * The `login` method validates the credentials.
 * Then it sends the user back to the `returnTo` state, which is provided as a resolve data.
 */
class LoginController {
    constructor(security, $state) {

        security.getRedirectResult().then((v) => {
            if (v && v.user) {
                const url = localStorage.getItem('REDIRECT_URL');
                if (url) {
                    window.location.href = url;
                } else { 
                    $state.go('welcome')
                }
            }
        });
        
        this.providers = [
            {
                icon: 'google',
                go: () => this.loginWith('google'),
                tooltip: 'Login with google'
            }
        ]

        this.credentials = {
            username: null,
            password: null
        };

        this.login = (credentials) => {
            console.log({credentials});
            this.authenticating = true;

            const returnToOriginalState = () => {
                let state = this.returnTo.state();
                let params = this.returnTo.params();
                let options = Object.assign({}, this.returnTo.options(), { reload: true });
                $state.go(state, params, options);
            };

            const showError = (errorMessage) =>
                this.errorMessage = errorMessage;

            security.login(credentials.username, credentials.password)
                .then(returnToOriginalState)
                .catch(showError)
                .finally(() => this.authenticating = false);
        }

        this.loginWith = (provider) => {
            this.authenticating = true;
            let state = this.returnTo.state();
            let params = this.returnTo.params();
            let url = $state.href(state, params, {absolute: true});
            
            const showError = (errorMessage) =>
                this.errorMessage = errorMessage;

            localStorage.setItem('REDIRECT_URL', url);
            security.loginWith(provider)
                .catch(showError)
                .finally(() => this.authenticating = false);
        }


    }
}
LoginController.$inject = ['security', '$state'];

/**
 * This component renders a faux authentication UI
 *
 * It prompts for the username/password (and gives hints with bouncy arrows)
 * It shows errors if the authentication failed for any reason.
 */
export const login = {
    bindings: { returnTo: '<' },

    controller: LoginController,

    template: `
        <md-content class="dialog-window" ui-view>
            <md-card>
                <md-card-title>
                    <md-card-title-text>
                        <span class="md-headline">Log in</span>
                    </md-card-title-text>
                </md-card-title>
                <form name="form" ng-submit="$ctrl.login($ctrl.credentials)">
                    <md-card-content>
                        <md-input-container class="md-block">
                            <label>Email</label>
                            <input name="login" type="email" ng-model="$ctrl.credentials.username" required autofocus>
                        </md-input-container>
                        <md-input-container class="md-block">
                            <label>Password</label>
                            <input name="pass" type="password" ng-model="$ctrl.credentials.password" required>
                        </md-input-container>
                        <a ui-sref="reset-password" href="#">Forgot password?</a>
                    </md-card-content>
                    <md-card-actions layout="row" layout-align="end center">
                        <md-button class="md-icon-button" ng-repeat="provider in $ctrl.providers" ng-click="provider.go()">
                            <i class="mdi mdi-{{ ::provider.icon }}"></i>
                            <md-tooltip>{{ ::provider.tooltip }}</md-tooltip>
                        </md-button>
                        <md-button ui-sref="register">Register</md-button>
                        <md-button type="submit" ng-disabled='form.$invalid || $ctrl.authenticating'>Log in</md-button>
                    </md-card-actions>
                </form>
            </md-card>
        </md-content>
    `
};