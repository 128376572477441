// app.js

"use strict";

// Dependencies
import angular from 'angular';
// import '@uirouter/angularjs';
// import '@uirouter/angularjs/release/stateEvents';
import 'angular-translate';
import 'angular-animate';
import 'angular-aria';
import 'angular-material';
import 'angular-messages';
import 'angular-sanitize';
// import 'angular-ui-carousel';
// import 'restangular';
// import "oclazyload";
// import "@mdi/font/scss/materialdesignicons.scss";

// Components
import './app.style.scss';
// import "../pages/home/home.module";

import { app } from "./app.component";
import { welcome } from "./welcome.component";
import { login } from "./login.component";
import { register } from "./register.component";
import { home } from "./home.component";
import {
    appState,
    homeState,
    loginState,
    registerState,
    welcomeState,
    productsFutureState,
    blueprintsFutureState
} from "./app.states";

import '/src/common/security';
import '/src/common/resources/backend';
// import '../pages/authenticate/authenticate';


export const MAIN_MODULE = angular
    .module("main", [
        "ui.router",
        'ui.router.state.events',
        "oc.lazyLoad",
        'ngSanitize',
        'pascalprecht.translate',
        "ngMaterial",
        'security',
        'resources.backend'
    ]);

// .config(appRouting)
MAIN_MODULE.config(['$uiRouterProvider', function ($uiRouter) {
    // $uiRouter.trace.enable(1);

    const $urlService = $uiRouter.urlService;
    $urlService.rules.otherwise({ state: 'welcome' });

    const $stateRegistry = $uiRouter.stateRegistry;
    $stateRegistry.register(appState);
    $stateRegistry.register(homeState);
    $stateRegistry.register(loginState);
    $stateRegistry.register(registerState);
    $stateRegistry.register(welcomeState);

    $stateRegistry.register(productsFutureState);
    $stateRegistry.register(blueprintsFutureState);
    // $stateRegistry.register(prefsFutureState);
    // $stateRegistry.register(mymessagesFutureState);
}]);

MAIN_MODULE.component('app', app);
MAIN_MODULE.component('welcome', welcome);
MAIN_MODULE.component('login', login);
MAIN_MODULE.component('register', register);
MAIN_MODULE.component('home', home);

MAIN_MODULE.constant('I18N.MESSAGES', {
    'errors.route.changeError': 'Route change error',
    'crud.user.save.success': "A user with id '{{id}}' was saved successfully.",
    'crud.user.remove.success': "A user with id '{{id}}' was removed successfully.",
    'crud.user.remove.error': "Something went wrong when removing user with id '{{id}}'.",
    'crud.user.save.error': "Something went wrong when saving a user...",
    'crud.project.save.success': "A project with id '{{id}}' was saved successfully.",
    'crud.project.remove.success': "A project with id '{{id}}' was removed successfully.",
    'crud.project.save.error': "Something went wrong when saving a project...",
    'login.reason.notAuthorized': "You do not have the necessary access permissions.  Do you want to login as someone else?",
    'login.reason.notAuthenticated': "You must be logged in to access this part of the application.",
    'login.error.invalidCredentials': "Login failed.  Please check your credentials and try again.",
    'login.error.serverError': "There was a problem with authenticating: {{exception}}."
});

MAIN_MODULE.config(['$mdThemingProvider', function ($mdThemingProvider) {
    const palatteColorKeys = ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900"];
    const palatteAltColorKeys = ["A100", "A200", "A400", "A700"];
    const darkBgConfigs = [
        ["800", "700", "800", "900"],
        ["700", "800", "700", "900"],
        ["700", "800", "800", "900"],
        ["800", "700", "700", "900"],
        ["800", "900", "900", "800"],
        ["600", "700", "700", "800"], // lightgrey
        ["600", "700", "700", "900"],
        ["600", "800", "700", "800"],
        ["900", "700", "700", "800"],
        ["700", "900", "900", "800"]
    ]
    const config = darkBgConfigs[2];
    const colors = {
        blue: {
            colors:     ["#E1F5FB", "#bce8f2", "#83D8ED", "#64c9e2", "#42bedb", "#21B2D5", "#1ca2c2", "#128da8", "#0b7a8f", "#015863"],
            altColors:  ["#e6f7ff", "#b3e8ff", "#80d8ff", "#67d0ff"],
            contrast:   ["50", "100", "200", "300", "400", "500", "600", "A100", "A200", "A400", "A700"],
            hues:       ["50", "200", "500", "900"],
            darkHues:   ["50", "200", "500", "900"]
        },
        yellow: {
            colors:     ["#fefde9", "#fcf9c8", "#FAF5A4", "#f8f081", "#f6ec66", "#f3e74e", "#F4D74B", "#f1c142", "#EEAA39", "#E7842A"],
            altColors:  ["#FFFFFF", "#FFFEF1", "#FFF8BE", "#FFF5A5"],
            contrast:   ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900", "A100", "A200", "A400", "A700"],
            hues:       ["200", "600", "800", "900"],
            darkHues:   ["200", "600", "800", "900"]
        },
        green: {
            colors:     ["#e3f5e7", "#bce5c3", "#8FD49D", "#60c377", "#3bb65d", "#00A942", "#87cf95", "#7cc98b", "#72c381", "#005810"],
            altColors:  ["#ACFFB8", "#79FF8D", "#46FF62", "#2CFF4C"],
            contrast:   ["50", "100", "200", "300", "400", "A100", "A200", "A400", "A700"],
            hues:       ["50", "200", "500", "900"],
            darkHues:   ["50", "200", "500", "900"]
        },
        purple: {
            colors:     ["#E9EBF5", "#c7cde9", "#a2acdb", "#7e8ccc", "#6172c1", "#4658B6", "#4050ac", "#3746A0", "#2f3c94", "#22297F"],
            altColors:  ["#CAD0FF", "#97A2FF", "#6474FF", "#4B5DFF"],
            contrast:   ["50", "100", "200", "300", "A100", "A200"],
            hues:       ["50", "500", "700", "900"],
            darkHues:   ["50", "200", "500", "900"]
        },
        red: {
            colors:     ['#f9e9e7', '#FBCDBE', '#f9ac94', '#f88c69', '#f77448', '#F65D2A', '#eb5726', '#DD5022', '#cf4a1e', '#B53F18'],
            altColors:  ["#FFFFFF", '#FFEBE8', '#FFBEB5', '#FFBEB5'],
            contrast:   ["50", "100", "200", "300", "400", "500", "A100", "A200", "A400", "A700"],
            hues:       ["100", "500", "700", "900"],
            darkHues:   ["100", "200", "500", "900"]
        },
        black: {
            colors:     ["#f7f7f7", "#eeeeee", "#e2e2e2", "#d0d0d0", "#ababab", "#8a8a8a", "#636363", "#505050", "#323232", "#121212"],
            altColors:  ["#CAD0FF", "#97A2FF", "#6474FF", "#4B5DFF"],
            contrast:   ["50", "100", "200", "300", "400", "500", "600", "A100", "A200"],
            darkHues:   config
        },
        white: {
            colors:     ["#ffffff", "#fafafa", "#f5f5f5", "#f0f0f0", "#dedede", "#c2c2c2", "#979797", "#818181", "#606060", "#3c3c3c"],
            altColors:  ["#ffffff", "#ffffff", "#ffe0e0", "#ffc7c7"],
            contrast:   ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900", "A100", "A200", "A300", "A400"],
            darkHues:   ["300", "50", "50", "400"]
        },
        velvet: {
            colors:     ['#f3e5f6', '#e1bee7', '#ce93d8', '#ba68c8', '#ab47bc', '#9c27b0', '#9423a9', '#8a1da0', '#801797', '#6e0e87'],
            altColors:  ['#efb8ff', '#e485ff', '#d852ff', '#d238ff'],
            contrast:   ["50", "100", "200", "300", "A100", "A200", "A400"],
            hues:       ["200", "500", "700", "900"],
            darkHues:   ["50", "200", "300", "500"]
        },
        beige: {
            colors:     ['#f8f4ef','#efe4d7','#e4d3bc','#d9c1a1','#d0b38c','#c8a678', '#c29e70', '#bb9565', '#b48b5b', '#a77b48'],
            altColors:  ['#ffffff', '#ffe7ce', '#ffcf9b', '#ffc381'],
            contrast:   ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700'],
            hues:       ["50", "200", "500", "900"],
            darkHues:   ["50", "200", "500", "900"]
        }
    }
    
    Object.keys(colors).forEach(colorKey => {
        let config = colors[colorKey];
        var palette = {};
        if (config.colors.length === palatteColorKeys.length && config.altColors.length === palatteAltColorKeys.length) {
            for (let index = 0; index < palatteColorKeys.length; index++) {
                palette[palatteColorKeys[index]] = config.colors[index];
            }
            for (let index = 0; index < palatteAltColorKeys.length; index++) {
                palette[palatteAltColorKeys[index]] = config.altColors[index];
            }
            palette['contrastDefaultColor'] = 'light';
            palette['contrastDarkColors'] = config.contrast;
            $mdThemingProvider.definePalette(colorKey, palette);
        } else {
            console.error(`Invalid number of colors in ${colorKey}`)
        }
    });
    
    function huesInOptionsFormat(hues) {
        if (hues && hues.length >= 4) {
            return {
                "hue-1": hues[0],
                "default": hues[1],
                "hue-2": hues[2],
                "hue-3": hues[3]
            }
        } else {
            return {}
        }
    }
    
    function addTheme(name, primary, accent, darkmode = false) {
        name = darkmode ? name + '_dark' : name;
        var theme = $mdThemingProvider.theme(name);
        if (darkmode) {
            theme.primaryPalette(primary, huesInOptionsFormat(colors[primary].darkHues));
            theme.accentPalette(accent, huesInOptionsFormat(colors[accent].darkHues));
            theme.backgroundPalette('black', huesInOptionsFormat(colors['black'].darkHues));
            theme.dark();
        } else {
            theme.primaryPalette(primary, huesInOptionsFormat(colors[primary].hues));
            theme.accentPalette(accent, huesInOptionsFormat(colors[accent].hues));
            theme.backgroundPalette('white', huesInOptionsFormat(colors['white'].darkHues));
        }
        return theme;
    }

    addTheme('default', 'green', 'yellow', false);
    addTheme('default', 'black', 'green', true);

    let defaultTheme = 'default';
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        defaultTheme += '_dark';
    }
    
    $mdThemingProvider.setDefaultTheme(defaultTheme);
    $mdThemingProvider.enableBrowserColor({
        theme: defaultTheme,
        palette: "primary"
    });
}]);

MAIN_MODULE.run(['$rootScope', '$state', '$log', function run($rootScope, $state, $log) {
    $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
        $log.log("$stateChangeError", { event, error });
        switch (error.detail) {
            // case "EMAIL_VERIFICATION_REQUIRED":
            //     $state.go("authenticate.verify");
            //     break;
            case "AUTH_REQUIRED":
                $log.log('redirect login');
                // $state.go("login");
                break;
            default:
                $log.warn(error);
                break;
        }
    });

    $rootScope.$on("$onSecurityEvent", function (event, error) {
        switch (error.detail) {
            case "AUTH_REQUIRED":
            case "SIGNED_OUT":
                $log.log('redirect to login');
                // $state.go("login");
                break;
            case "USER_LOGGED_IN":
                // $state.go("welcome");
                break;
            default:
                $log.warn(error);
                break;
        }
    });
}]);

MAIN_MODULE.config(['$logProvider', '$provide', function ($logProvider, $provide) {
    $logProvider.debugEnabled(process.env.NODE_ENV === 'development');
    $provide.decorator('$log', ['$delegate', function ($delegate) {
        $delegate.info = $logProvider.debugEnabled() ? $delegate.info : function () { };
        $delegate.log = $logProvider.debugEnabled() ? $delegate.log : function () { };
        return $delegate;
    }]);
}]);